var contact = document.getElementById("contact");
var contact_data = document.getElementById("contact_info");

contact.addEventListener("click", (e)=>{
    console.log(e)
    if(contact_data.style.marginLeft == 3.5+"vw"){
    contact_data.style.marginLeft = 120+"vw";
    } else {
        contact_data.style.marginLeft = 3.5+"vw"
    }
})


var video_button = document.getElementById("videos")
var landing = document.getElementById("landing");
var djuwa = document.getElementsByClassName("djuwa")

console.log(djuwa);

function opening(element){
    element.classList.remove("close");
    element.classList.add("open");
}

function closing(element){
    console.log(element);
    element.classList.remove("open");
    element.classList.add("close");
}

for(let element of djuwa){
    element.addEventListener("click", (e)=>{
        if(video.classList.contains("open")){
            closing(video)
            opening(landing)
        }
    })
};


video_button.addEventListener("click", (e)=>{
    console.log(video.classList.contains("close"))
    if(video.classList.contains("close")){
        console.log("haha bitch")
        closing(landing)
        opening(video)
    }
    else if(video.classList.contains("open")){
        closing(video)
        opening(landing)
    }
    
})